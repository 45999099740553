.App {
  text-align: center;
}

.ml11 {
  font-weight: 700;
  font-size: 3.5em;
}

.ml11 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.1em;
  padding-right: 0.05em;
  padding-bottom: 0.15em;
}

.ml11 .line {
  opacity: 0;
  position: absolute;
  left: 0;
  height: 100%;
  width: 3px;
  background-color: #fff;
  transform-origin: 0 50%;
}

.ml11 .line1 { 
  top: 0; 
  left: 0;
}

.ml11 .letter {
  display: inline-block;
  line-height: 1em;
}


.container__list {
  width: 75%;
  padding-left: 5vw;
  list-style: none;
  text-align: left;
  justify-content: center;
  align-items: center;
}

.container__heading {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.container__second-heading {
  font-style: italic;
}

.container__list-block {
  background-color: #282c34;
  color: white;

}

.image {
  width: 10%;
}

.link{
  color: rgb(255, 190, 190);
  text-decoration: none;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.container__blog-input {
  justify-content: right;
  align-items: right;
  width: 25%;
  padding: 5px;
  margin-bottom: 2rem;
  height: 30px;
  width: 16rem;
  border: 1px solid grey;
}

.container__blog-input-content {
  width: 100%;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
  font-weight: 300;
  font-size: 0.5em;
}

.container__markdown {
  display: flex;
  flex-direction: row;
}

.container__login-input {
  justify-content: right;
  align-items: right;
  width: 25%;
  padding: 5px;
  margin-bottom: 2rem;
  height: 30px;
  width: 16rem;
  border: 1px solid grey;
}

.container__login-input:focus {
  outline: antiquewhite;
}


.container__login-input-s {
  height: 35px;
  color: #282c34;
  background-color: rgb(255, 190, 190);
  border: none;
  letter-spacing: 0.2rem;
  transition: 0.3s opacity ease;
  cursor: pointer;
}

.container__test {
  background-color: #1abc9c;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
  width: 100%;
}

.container__form {
  display: flex;
  flex-direction: column;
 
}

.card{
  background-color: #fff;
  border-radius: 15px;
  padding: 0.8rem;

}

.card > h3 {
  color: #282c34;

}

.card > form {
  display: flex;
  flex-direction: column;
}

.container__blog-list {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container--unmodded {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

/*
  pink  - #ff299e
  dark  - #282c34
  light - #d8d8d8
*/

.container--theme-dark {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.container--theme-pink {
  background-color: #ff299e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.container--theme-light {
  background-color: #d8d8d8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.container--theme-40 {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.container__footer {
  background-color: rgb(253, 183, 183);
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: right;
  font-size: calc(10px + 2vmin);
}

.container__footer > div {
  padding-left: 10vw;
  padding-top: 3.5vh;
}

.link--footer {
  text-decoration: none;
  color: white;
}

.container--theme-w75 {
  width: 75%;
}

.container__side-menu {
  width: 10vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.container__side-menu__button {
  font-family: 'Raleway', sans-serif;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #FFF;
  padding:0px;
  margin:0px;
  background-color: rgba(0, 0, 0, 0);
}

.container__admin-panel-content {
  width: 90vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container__blog-post {
  border: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 2% 0%
}

.blog-post__content {
  margin-left: 5%;
  margin-right: 5%;
}

.container__text {
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 70%;
}

.navigator__text {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

::-webkit-scrollbar { 
  display: none; 
}

input, button, submit { border:none; } 